<template>
    <div>
        <v-row justify="center" v-if="!reverse">
            <v-col cols="12" md="6">
                <v-img :src="imageUrl" :key="imageUrl"></v-img>
            </v-col>
            <v-col align="left">
                <div
                    :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                    class="font-weight-thin"
                    v-html="lead"
                ></div>
                <div :class="smAndUp ? 'text-body-1' : 'text-body-2'">
                    {{ desc }}
                </div>
            </v-col>
        </v-row>
        <v-row justify="center" v-else>
            <v-col align="left">
                <div
                    :class="smAndUp ? 'text-h4 mb-10' : 'text-h6'"
                    class="font-weight-thin"
                    v-html="lead"
                ></div>
                <div :class="smAndUp ? 'text-body-1' : 'text-body-2'">
                    {{ desc }}
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <v-img :src="imageUrl" :key="imageUrl"></v-img>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        imageUrl: {
            required: true,
            type: String,
        },
        lead: {
            required: true,
            type: String,
        },
        desc: {
            required: true,
            type: String,
        },
        reverse: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped></style>
