<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- トレーナー -->
        <v-container>
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <h1><heading>トレーナー</heading></h1>
                </v-col>
                <v-col cols="12" align="center">
                    <v-img :src="imageMv" :key="imageMv"></v-img>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="12">
                    <block
                        :imageUrl="imageTrainer2"
                        lead='<span class="red--text">トレーナー</span>のこだわり'
                        desc="REVOISTのトレーナーは指導経験豊富の即戦力かつ親切なトレーナーしか採用しておりません。 指導スキルや知識はもちろんのこと、カラダの悩みや日頃の日常会話も楽しみながら、ひとりひとりの価値観に寄り添ったレッスンができるトレーナー集団です。"
                        :reverse="!smAndUp"
                        :class="smAndUp ? 'mb-15' : null"
                    ></block>
                    <block
                        :imageUrl="imageTrainer1"
                        lead='<span class="red--text">多数在籍</span>のトレーナーが強み'
                        desc="基本的な担当トレーナーで安定してレッスンをお受けいただけるのはもちろんのこと、振替や曜日変更などでいつもと違ったREVOISTのトレーナーのレッスンを受けることで、新たなメニューや知識やモチベーションを得ることもできます。 自分の性格にあったトレーナーでレッスンを続けることができます。"
                        :reverse="true"
                        :class="smAndUp ? 'mb-15' : null"
                    ></block>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col md="8">
                    <link-tag compName="Testimonial">お客様の声</link-tag>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import LinkTag from '@/components/LinkTag.vue'
import Block from '@/components/ourteam/Block.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        FooterContents,
        LinkTag,
        Block,
    },

    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'トレーナー',
                    disabled: true,
                    href: 'ourteam',
                },
            ]
        },

        imageMv() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_ourteam/pixta_65324547_XL.png'
                : 'static/sp_ourteam/ourteam_img01.png'
        },
        imageTrainer1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_ourteam/IMG_2042.png'
                : 'static/sp_ourteam/ourteam_img03.png'
        },
        imageTrainer2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_ourteam/IMG_9986.png'
                : 'static/sp_ourteam/ourteam_img02.png'
        },
    },
}
</script>

<style lang="scss" scoped></style>
